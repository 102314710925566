<template>
  <secondary-page-layout title="Продление ОГПО">
    <div class="form-group px-3">
      <div>
        <v-dialog />

        <input-field
            :label="$t('enterPhoneNumber')"
            :is-block="true"
            field-type="tel"
            mask="+7 (###) ###-##-##"
            name="phone"
            :addition="$t('cantChangeNumber')"
            v-model="phone_number"
            :disabled="true"
        />

        <add-person-info
            :persons="clients"
            :addBtnDisabled="personBtnIsDisabled"
            :addNewPersonInput="personAddNewInputShow"
            :personIin="clientIin"
            :error="clientError"
            @addNewField="personAddNewField"
            @input="validateIin"
            @delete="deletePerson"
            :title="$t('drivers')"
            :button-title="$t('addDriver')"
        />

        <add-car-info
            :cars="cars"
            :addBtnDisabled="carsBtnIsDisabled"
            :addNewCarInput="carAddNewInputShow"
            :carNumber="carNumber"
            :error="carError"
            @addNewField="carAddNewField"
            @input="validateCarNumber"
            @delete="deleteCar"
            :title="$t('cars')"
            :button-title="$t('addCar')"
            ref="carInfoInput"
        />

        <radio-input-list
            :read-more="false"
            :options="monthsOptions"
            radio-name="periods"
            v-model="period"
        />

        <select-date
            :is-disabled-before-today="true"
            :is-block="true"
            :customText="customText"
            :title="$t('agreementDate')"
            name="start_date"
            v-model="beginDate"
            :until="initialDate"
        />

        <input-field
            label="Email"
            field-type="email"
            :addition="$t('emailNotNecessary')"
            v-model="email"
            name="email"
            :is-block="true"
        />

        <ogpo-extension-products @updateSum="getFixSumByProduct"/>

        <ogpo-extension-add-casco-lite />

        <div v-if="calculatedSum" class="final-amount-wrapper">
          <final-amount
              :amount="calculatedSum.addPayment"
              :fixSum="calculatedSum.fixedSum"
              :ogpo="fixSumParameters"
              @submit="proceedToPayment"
          />
        </div>
      </div>
    </div>
  </secondary-page-layout>
</template>

<script>

import AddPersonInfo from "/src/components/BuyPolicy/AddPersonInfo";
import AddCarInfo from "/src/components/BuyPolicy/AddCarInfo";
import SelectDate from "/src/components/BuyPolicy/SelectDate";
import RadioInputList from "/src/components/BuyPolicy/RadioInputList";
import InputField from "/src/components/BuyPolicy/InputField";
import FinalAmount from "/src/components/BuyPolicy/FinalAmount";
import AddCascoLite from "/src/components/BuyPolicy/AddCascoLite";
// import BonusModal from "/src/componentsModals/BonusModal";
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import OgpoExtensionProducts from '/src/components/BuyPolicy/OgpoExtensionProducts';
import LoyalClientModal from '/src/components/Modals/LoyalClientModal';
import eventLogger from "/src/services/eventLogger";
import OgpoExtensionAddCascoLite from "../components/BuyPolicy/OgpoExtensionAddCascoLite";
import ogpoFormMixin from "../mixin/ogpoFormMixin";
import ClientCheckModal from "../components/Modals/ClientCheckModal";
import redmine from "../mixin/redmine";

export default {
  name: "OgpoExtension",
  mixins: [ogpoFormMixin, redmine],
  components: {
    ClientCheckModal,
    OgpoExtensionAddCascoLite,
    AddPersonInfo,
    AddCarInfo,
    RadioInputList,
    SelectDate,
    InputField,
    FinalAmount,
    OgpoExtensionProducts,
  },
  data() {
    return {
      storePrefix: 'ogpoExtension',
      monthsOptions: [
        { name: this.$i18n.t('month6'), value: 6 },
        { name: this.$i18n.t('month12'), value: 12 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      products: 'ogpoExtension/products',
      productsRegion: 'ogpoExtension/productsRegion',
      autoplusProduct: 'ogpoExtension/autoplusProduct',
      availableProducts: 'ogpoExtension/availableProducts',
      isLoyalClient: 'ogpoExtension/isLoyalClient',
      minRiskRating: 'ogpoExtension/minRiskRating',
      carYearAndCarType: 'ogpoExtension/carYearAndCarType',
      clients: 'ogpoExtension/clients',
      cars: 'ogpoExtension/cars',
      endDate: 'ogpoExtension/endDate',
      casco: 'ogpoExtension/casco',
      calculatedSum: 'ogpoExtension/calculatedSum',
      cascoReward: 'ogpoExtension/cascoReward',
      addSumError: 'ogpoExtension/addSumError',
      clientsChecked: 'ogpoExtension/clientsChecked',
      form: 'ogpoExtension/finalFormValue'
    }),

    fixSumParameters() {
      const { calculatedSum, selectedProductId } = this;

      let params = [
        {
          name: this.$i18n.t('discount10'),
          price: `-${calculatedSum.saleOnline}`
        },
        {
          name: this.$i18n.t('autoplusCost'),
          price: calculatedSum.costProduct,
        },
        {
          name: this.$i18n.t('productDiscount'),
          price: `-${calculatedSum.saleCostProduct}`,
        },
        {
          name: this.$i18n.t('cascoLight'),
          price: calculatedSum.kasko
        },
        {
          name: this.$i18n.t('bonusSum' + selectedProductId),
          price: calculatedSum.bonusSum
        }
      ];

      return params.filter((p) => p.price != 0 || p.price != "-0");
    },
  },
  async created() {
    eventLogger('view_ogpo_screen', {product_type: 1000})

    await this.setDefaultInfo();
  },
  mounted() {
    this.getOgpoProduct();

    this.$watch('loyalClientModal', (needShow) => {if(needShow) { this.showLoyalClientModal(); } else { this.$modal.hide('dialog') }} )
  },
  methods: {
    ...mapActions({
      changePhoneNumber: 'ogpoExtension/changePhoneNumber',
      getPeopleByIin: 'ogpoExtension/getPeopleByIin',
      getCarByLicensePlate: 'ogpoExtension/getCarByLicensePlate',
      getProducts: 'ogpoExtension/getProducts',
      deletePerson: 'ogpoExtension/deletePerson',
      deleteCar: 'ogpoExtension/deleteCar',
      isHaveBasketFriend: 'ogpoExtension/isHaveBasketFriend',
      changeSelectedProductId: 'ogpoExtension/changeSelectedProductId',
      changeSelectedProductName: 'ogpoExtension/changeSelectedProductName',
      getFixSum: 'ogpoExtension/getFixSum',
      getFixSumByProduct: 'ogpoExtension/getFixSumByProduct',
      getCascoLite: 'ogpoExtension/getCascoLite',
      checkClientsDoc: `ogpoExtension/checkClientsDoc`,
      setTempPolicy: 'ogpoExtension/setTempPolicy',
      sumVerification: 'ogpoExtension/sumVerification',
      resetForm: 'ogpoExtension/resetForm',
      saveDraft: `ogpoExtension/saveDraft`
    }),

    async getOgpoProduct() {
      const {data} = await window.axios.get('insurance-types/1');
      this.$store.commit("SET_INSURANCE_PRODUCT", data.products[0]);
    },

    async setDefaultInfo(){
      this.agreement_id = this.$route.params.id;

      this.resetForm();

      const loader = this.$loading.show();

      const {data} = await window.axios.get(`policies/${this.agreement_id}`);

      this.agreement = data;

      this.phone_number = `7${this.authUser.phone_number}`

      this.changePhoneNumber(this.authUser.phone_number)

      const {insureds, objects, holder} = this.agreement;
      const {bin} = holder
      const holderIndex = insureds.findIndex(item => item.bin === bin)

      const sortedInsureds = insureds.swap(0, holderIndex);

      for(let i = 0; i < sortedInsureds.length; i++) {
        await this.validateIin(sortedInsureds[i].bin)
      }

      for(let i = 0; i < objects.length; i++) {
        await this.validateCarNumber(objects[i].license_plate)
      }

      this.initialDate = moment().add(1,'days');
      this.$store.commit('ogpoExtension/SET_BEGIN_DATE', this.agreement.endDate.date)

      loader.hide();
    },

    async recalculate() {
      if(this.clientChecking) {
        return false;
      }

      this.$store.commit("ogpoExtension/SET_CALCULATED_SUM", null);

      const { cars, clients,  $loading } = this;

      if (cars.length === 0 || clients.length === 0) {
        this.selectedProductId = 0
        this.selectedProductName = "";
        this.$store.commit("ogpoExtension/SET_PRODUCTS",  []);
        return
      }

      const loader = $loading.show({});

      await this.getCascoLite();
      await this.isHaveBasketFriend();
      await this.getProducts();

      if (this.selectedProductId === 0 && this.products.length > 0) {
        const [selectedProduct] = this.products

        this.selectedProductId = selectedProduct["mergeProduct"]["id"]
      }

      await this.getFixSum();
      await this.getFixSumByProduct();

      loader.hide()
    },

    async proceedToPayment() {
      const { addSumError, cars, clients, period, selectedProductId, phone_number } = this

      const loader = this.$loading.show();

      this.clientChecking = true
      try{
        await this.checkClientsDoc();
      } catch (e) {
        this.showClientCheckModal();
        return loader.hide();
      }
      this.clientChecking = false;

      if (addSumError) {
        await this.recalculate();

        return loader.hide();
      }

      if (cars.length === 0 || clients.length === 0) {
        loader.hide();
        return
      }

      if (period < 12 && selectedProductId !== 0 && selectedProductId !== 10) {
        this.$store.commit("ogpoExtension/SET_ADDSUMERROR", "Выбран некорректный баскет");
      }

      if (selectedProductId === 16) {
        this.$store.commit("ogpoExtension/SET_FRIEND_BASKET", false);
      }

      const tempPhone = phone_number.replace(/[()+\- ]/g, "");

      //phone number verification
      if (!tempPhone.match(/^7\d{9}$/g)) {
        loader.hide();
        this.errorMessage = this.$i18n.t("sumStep.errors.phoneError");
        return;
      }

      try{
        await this.setTempPolicy();
      } catch (e) {
        loader.hide();
        this.$modal.show('dialog', {
          title: 'Произошла ошибка',
          text: e?.response?.message || e?.message,
          buttons: [{
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
              return null;
            }
          }]
        }, {class: 'error'});

        this.onError({
          message: e?.response?.message || e?.message || 'Ошибка при создании полиса',
          code: e?.statusCode || 0,
          title: `Ошибка при создании полиса`,
        });

        return;
      }

      try{
        await this.sumVerification();
      }catch (e) {
        loader.hide();
        this.$modal.show('dialog', {
          title: 'Произошла ошибка',
          text: e?.response?.message || e?.message,
          buttons: [{
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
              return null;
            }
          }]
        }, {class: 'error'});

        this.onError({
          message: e?.response?.message || e?.message || 'Ошибка при сверке суммы',
          code: e?.statusCode || 0,
          title: `Ошибка при сверке суммы`,
        });

        return;
      }

      loader.hide();

      await this.$router.push({
        name: "ogpo-extension.form.confirm",
      });
    },
  },
};
</script>

<style scoped>

</style>
