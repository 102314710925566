<template>
  <div v-if="isProductNull">
    <div class="primary-block" v-if="availableBonusProduct">

      <h2 class="input-list-title">{{ $t('additionalProduct') }}</h2>
      <ul class="input-list">
        <li class="input-list-item">
          <div>
            <label class="primary-text label" for="autoplus_product">
              {{ availableBonusProduct.name }}
            </label>
            <span class="success-text" @click="showBonusDetail(bonusProduct)">{{$t('details')}}</span>
          </div>

          <input
              type="checkbox"
              id="autoplus_product"
              v-model="friendBasket"
          />
        </li>
      </ul>
    </div>

    <radio-input-list
        v-if="availableProducts && availableProducts.length"
        :input-type="inputType"
        :read-more="true"
        :options="availableProducts"
        radio-name="bonuses"
        :title="$t('selectBonusProduct')"
        :value="selectedProductId"
        @input="setProductId"
        @more="showBonusDetail"
    />
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import RadioInputList from './RadioInputList.vue';
import BonusModal from "../Modals/BonusModal";
export default {
  components: { RadioInputList },
  name: "OgpoExtensionProducts",
  data() {
    return {
      showProductInfo: false,
      selectedProductIndex: null,
      chooseavtoplus: true,
    };
  },
  methods: {
    setProductId(id) {
      id = Number(id);
      const { cars, clients } = this;

      if (cars.length === 0 || clients.length === 0) {
        return;
      }

      if ((id == 12 || id == 16) && this.chooseavtoplus){
        if (id == 16) {
          this.$store.commit("ogpoExtension/SET_FRIEND_BASKET", false);
        }

        this.$store.commit("ogpoExtension/SET_SELECTED_PRODUCT_ID", 0);

        this.chooseavtoplus = false;
        this.$emit("updateSum");
        return;
      }

      if (id != this.selectedProductId) {
        this.$store.commit("ogpoExtension/SET_SELECTED_PRODUCT_ID", id);
        if (!this.chooseavtoplus){
          if (id == 16){
            this.$store.commit("ogpoExtension/SET_FRIEND_BASKET", true);
          }
          this.chooseavtoplus = true;
        }
        this.$emit("updateSum");
      }
    },
    showBonusDetail(product) {
      this.$modal.show(BonusModal, { info: product }, this.$modalConfig)
    }
  },
  computed: {
    ...mapGetters({
      minRiskRating: 'ogpoExtension/minRiskRating',
      products: 'ogpoExtension/products',
      isBasketFriend: 'ogpoExtension/isBasketFriend',
      cars: 'ogpoExtension/cars',
      clients: 'ogpoExtension/clients',
      selectedProductId: 'ogpoExtension/selectedProductId',
      availableProducts: 'ogpoExtension/availableProducts'
    }),
    friendBasket: {
      get() {
        return this.$store.getters['ogpoExtension/friendBasket'];
      },
      set(value) {
        this.$store.commit("ogpoExtension/SET_FRIEND_BASKET", value);
        this.$emit("updateSum");
      }
    },
    isProductNull() {
      return !!this?.products?.length
    },
    inputType() {
      return this?.products?.length === 1 ? 'checkbox' : 'radio'
    },
    availableBonusProduct() {
      const bonus =  this?.products?.find(product => product['mergeProduct'].id === 16)

      if(!bonus) {
        return
      }
      return bonus.mergeProduct
    },
    bonusProduct() {
      const bonus =  this?.products?.find(product => product['mergeProduct'].id === 16)

      if(!bonus) {
        return
      }

      return bonus
    }
  }
};
</script>
